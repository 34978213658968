import { AttendanceService } from '@services/attendance.service'

export default {
  async updateAttendanceOfSetionStudent({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await AttendanceService.updateAttendanceOfSetionStudent(
      payload,
      commit,
    )
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async updatePeriodAttendanceOfStudent({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await AttendanceService.updatePeriodAttendanceOfStudent(
      payload,
      commit,
    )
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async getAttendanceOfSetionStudentBYId({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await AttendanceService.getAttendanceOfSetionStudentBYId(
      payload,
      commit,
    )
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },

  async markPeriodAttendance({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await AttendanceService.markPeriodAttendance(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    commit('layout/IS_LOADING', false, { root: true })
    return [response, error]
  },
  getPeriodAttendanceDetails({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      AttendanceService.getPeriodAttendanceDetails(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
}
