import i18n from '@src/i18n'
import StudentService from './services'
import { fullName } from '@/src/utils/settings/tenant-user.util'

const t = i18n?.global?.t

export default {
  setFiltersForMarkAttendance({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_CURRENT_ATTENDANCE_FILTER', payload)
      resolve('Done')
    })
  },

  getScheduledStudents({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getScheduledStudents(payload)
        .then((response) => {
          commit('SET_SCHEDULED_STUDENT_LIST', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  markAttendanceForStudent({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.markAttendanceForStudent(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  SubmitAttendance({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      payload = {
        ...payload,
        section_class_subject_id: rootState.layout.currentSectionScope.section_class_subject[0].id,
      }
      StudentService.SubmitAttendance(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Attendance marked successfully`) },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit(
            'toast/NEW',
            { message: t(`toast.Attendance already mark`), type: 'error' },
            { root: true },
          )
          reject(error)
        })
    })
  },

  setStudentsCheckIn({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.setStudentsCheckIn({
        checked_at: payload.time,
        checked_type: 'checked_in',
        date: payload.date,
        user_id: state.currentSelectedStudent.id,
      })
        .then((response) => {
          commit('toast/NEW', { message: t(`toast.Check in Successfully`) }, { root: true })
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message }, { root: true })
        })
    })
  },

  setStudentsCheckOut({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.setStudentsCheckOut({
        checked_at: payload.time,
        checked_type: 'checked_out',
        date: payload.date,
        user_id: state.currentSelectedStudent.id,
      })

        .then((response) => {
          commit('toast/NEW', { message: t(`toast.Check out Successfully`) }, { root: true })
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getStudentCheckHistory({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const date = new Date()
      StudentService.getStudentCheckHistory({
        user_id: state.currentSelectedStudent.id,
        date: payload || `${date.getFullYear()}-${date.getMonth() + 1}`,
      })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  setCurrentStudent({ state, commit, rootState }, payload) {
    commit('SET_CURRENT_SELECTED_STUDENT', payload)
  },

  /// //////Class Attendance Works
  setCurrentClassAttendance({ state, commit, rootState }, payload) {
    commit('SET_CURRENT_CLASS_ATTENDANCE', payload)
  },

  getAllClasses({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getAllClasses(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getSubjects({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getSubjects({
        section_id: rootState.layout.currentSectionScope.id,
        date: payload,
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getClassListClass(classList, listId) {
    return classList.filter((item) => {
      if (item.id === listId) {
        return true
      } else {
        return false
      }
    })[0]
  },

  getStudentAttendanceHistory({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getStudentAttendanceHistory(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },
  getStudentAttendanceHistoryPerDay({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const date = new Date()
      StudentService.getStudentAttendanceHistoryPerDay({
        section_class_subject_id: rootState.layout.currentSectionScope.section_class_subject[0].id,
        student_id: payload.studentId,
        date: new Date(payload.date || `${date.getFullYear()}-${date.getMonth() + 1}`),
      })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
        })
    })
  },

  getStudentPeriodAttendance({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getStudentPeriodAttendance(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getTimeForSubjects({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getTimeForSubjects(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  loadStudentCheckInAndOut({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.loadStudentCheckInAndOut(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  loadSectionAttendance({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.loadSectionAttendance(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  loadCampusStudentsAttendance({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.loadCampusStudentsAttendance(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  loadStudents({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      StudentService.loadStudents(payload)
        .then((response) => {
          commit('SET_CURRENT_STUDENT_LIST', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  loadCampusStudents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.loadCampusStudents(payload)
        .then((response) => {
          commit('SET_CURRENT_STUDENT_LIST', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  loadClassStudents({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      StudentService.getStudentsWithFilters(payload)
        .then((response) => {
          commit('SET_CURRENT_STUDENT_LIST', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getStudentsWithFilters({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      StudentService.getStudentsWithFilters(payload)
        .then((response) => {
          response.data.records.forEach((student) => fullName(student))
          commit('SET_CURRENT_STUDENT_LIST', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  getStudentsStats({ commit }, payload = '') {
    return new Promise((resolve, reject) => {
      StudentService.getStudentsStats(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  markStudentCheckInOut({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      StudentService.markStudentCheckInOut(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  markStudentCampusAttendance({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      StudentService.markStudentCampusAttendance(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  editStudentCampusAttendance({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      StudentService.editStudentCampusAttendance(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  addNewStudent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return StudentService.addNewStudent(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: t(`toast.Successfully Created`) },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  editStudent({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      return StudentService.editStudent(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: t(`toast.Successfully Updated`) },
            { root: true },
          )
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  seedStudent({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      return StudentService.seedStudent({ csv_data: payload })
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: t(`toast.Successfully Added`) },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getMarkedAttendanceStudentList({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getMarkedAttendanceStudentList(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getStudentReceivableFee({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getStudentFee(payload)
        .then((response) => {
          commit('SET_STUDENT_RECEIVABLE_FEES', response?.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  getStudentsReceivedFees({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getStudentFeeReceived(payload)
        .then((response) => {
          response.data.records.forEach((record) => {
            fullName(record.student)
          })
          commit('SET_STUDENT_RECEIVED_FEES', response?.data)
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  promoteStudents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.promoteStudents(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  getPublishedExamsForPromotions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getPublishedExamsForPromotions(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  getPublishedExamsStudents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getPublishedExamsStudents(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },
  getStudentForPromotions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getStudentForPromotions(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  closeCurrentSection({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.closeCurrentSection(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error.message)
        })
    })
  },

  /** /
   * Student Mode Endpoints
   */

  getStudentSchedule({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getStudentSchedule(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  getStudentCheckInOut({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.getStudentCheckInOut(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  /**
   * Enrolls student in campus and sections
   * @param {Object} student_enrollment_object
   */

  studentEnrollment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.studentEnrollment(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  enrollNewStudent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.enrollNewStudent(payload)
        .then((response) => {
          commit('toast/NEW', { type: 'success', message: 'Successfully Enrolled' }, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  studentPromotion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.studentPromotion(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  passedStudentOut({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.passedStudentOut(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  associateStudentWithGuardian({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.associateStudentWithGuardian(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: 'Successfully Associated' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  unAssociateStudentWithGuardian({ commit }, payload) {
    return new Promise((resolve, reject) => {
      StudentService.unAssociateStudentWithGuardian(payload)
        .then((response) => {
          commit(
            'toast/NEW',
            { type: 'success', message: 'Successfully Unassociated' },
            { root: true },
          )
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },

  // Registration Process
  addStepForForm({ commit }, payload) {
    commit('SET_STEP_TYPE', payload?.type)
    commit('SET_FORM_TYPE_MODAL', true)
  },

  async getSingleStudentGaurdians({ commit }, payload) {
    commit('layout/IS_LOADING', true, { root: true })
    const [response, error] = await StudentService.getSingleStudentGaurdians(payload, commit)
    if (error) {
      commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
    }
    return [response, error]
  },

  studentEnrolledClasses({ commit }, id) {
    return new Promise((resolve, reject) => {
      StudentService.studentEnrolledClasses(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
  studentEnrolledSections({ commit }, id) {
    return new Promise((resolve, reject) => {
      StudentService.studentEnrolledSections(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('toast/NEW', { message: error.message, type: 'error' }, { root: true })
          reject(error)
        })
    })
  },
}
