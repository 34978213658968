import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import i18n from '@src/i18n/index'
import store from '@state/store'
import router from '@router'

const t = i18n?.global?.t

/**
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */
function _getStatusMessage(status) {
  let message = ''
  switch (status) {
    case 200:
      message = 'All done. Request successfully executed'
      break
    case 201:
      message = 'Successfully Created'
      break
    case 400:
      message = 'Your request cannot be processed at the moment'
      break
    case 401:
      message = 'Not Authorized'
      logOut()
      break
    case 404:
      message = 'Not found'
      break
    case 422:
      message = 'Error occured when attempting !'
      break
    case 500:
      message = 'Something went wrong. Try again later'
      break
    case 503:
      message = 'Service unavailable. Try again later'
      break
    default:
      message = 'Something went wrong. Try again later'
      break
  }
  return message
}

function transformError(error) {
  if (error.errors) {
    // const errors = Object.keys(error.errors)
    // if (errors.length > 1) {
    //   return error.errors
    //   // return errors.reduce((acc, value) => {
    //   //   return { ...acc, [value]: error.errors[value][0] }
    //   // }, {})
    // } else {
    //   const firstKey = errors[0]
    //   return error.errors[firstKey][0]
    // }
    return error.errors
  } else {
    return error.message
  }
}

function _getResponseErrors(error) {
  // going to disable this check, it is not valid way for its implementation
  // if (error.response?.data?.error?.errors?.user_id?.[0].includes('does not exist')) {
  //   store.dispatch('auth/logout')
  //   return error.errors?.user_id[0]
  // }
  // handle single case error
  // if (error.response?.data?.error?.errors) {
  //   const message = Object.keys(error.response.data.error.errors).map(e => error.response.data.error.errors[e][0])
  //   return message[0]
  // }
  if (error.response?.data?.error) {
    return transformError(error.response?.data.error)
  }
  if (error.response && error.response.statusText) {
    return error.response.statusText
  }
  return error.message === 'Network Error'
    ? t('toast.Oops! Network Error Try again later')
    : error.message
}

function _getResponseErrorMessage(error, message) {
  if (error.response?.data?.error && error.response?.data?.error?.errors) {
    let key = `${Object.keys(error.response?.data.error.errors)}`.replaceAll('_', ' ')
    let value = `${Object.values(error.response?.data.error.errors)}`
    return key + ' ' + value
  }
  if (
    error.response?.data?.error?.message &&
    error.response?.data?.error?.message?.toLowerCase() !== 'unprocessable entity'
  ) {
    return error.response?.data?.error?.message
  }

  if (
    error.response?.statusText &&
    error.response?.statusText.toLowerCase() !== 'unprocessable entity'
  ) {
    return error.response.statusText
  }
  if (error.response && error.response.data == 'Permission denied') {
    return 'Forbidden'
  }

  return error.message === 'Network Error' || error.message === 'timeout of 7000ms exceeded'
    ? t('toast.Oops! Network Error Try again later')
    : message
}
/**
 * Create instant, which represent response object
 * @param {Object} [data] - custom data
 * @param {Object} [response] - axios response object
 * @param {String} [message] - custom message to display
 */
export class ResponseWrapper {
  constructor(response, data = {}, message) {
    this.data = data
    this.success = response.data.success
    this.status = response.status
    this.statusMessage = _getStatusMessage(this.status)
    this.message = message || null
  }
}

/**
 * Create instant, which represent error object
 * @param {Object} [error] - axios error object
 * @param {String} [message] - custom message to display
 */
export class ErrorWrapper extends Error {
  constructor(error, message = undefined) {
    super()
    this.errors = _getResponseErrors(error, message)
    this.success = error.response ? error.response.data.success : false
    this.meta = error.response ? error.response.data.meta : false
    this.code = error.response ? error.response?.data?.error?.code : false
    this.status = error.response ? error.response.status : false
    this.statusMessage = _getStatusMessage(this.status)
    this.message = _getResponseErrorMessage(error, message)
  }
}

/**
 * Uses to clear request data before send it
 * Client shouldn't change entity id
 * @param data
 * @return {{}}
 */
export function clearData(data) {
  const result = {}
  forEach(data, (item, propName) => {
    if (isArray(item) && item.length) {
      result[propName] = item
    }
    if (!isArray(item) && item && propName !== 'id') {
      result[propName] = item
    }
  })
  return result
}
/*
 * If the session expired this function clear the vuex state
 * Clear localStograge
 * Show Session Expired toast
 * Redirect to logIn page
 */
function logOut() {
  store.commit('auth/LOGOUT_CURRENT_USER', '', { root: true })
  store.commit('layout/SET_SELECTED_CAMPUSE', '', { root: true })
  store.commit('layout/SET_SELECTED_ROLE', '', { root: true })
  store.commit('layout/SET_CURRENT_SELECTED_SECTION_SCOPE', '', { root: true })
  localStorage.clear()
  router.push({ name: 'login' })
}
