import $store from '@state/store'
import $router from '@router'
import i18n from '@src/i18n'
import GENERAL_CONSTANT from '@src/constants/general-constants'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'

/**
 * Get style according to user acouunt status
 * @param {String} status
 */

const t = i18n?.global?.t

export function pickupRequestStyle(status, stylepadding = false, minWid = false) {
  const style = {
    color: '',
    backgroundColor: '',
    borderRadius: '5px',
    padding: stylepadding || '10px 17px',
    minWidth: minWid || '28px',
  }

  if (status === 'active' || status === 'processed') {
    style.color = '#267C26'
    style.backgroundColor = '#E9F2E9'
  } else {
    style.color = '#EA3535'
    style.backgroundColor = '#FCE1E1'
  }
  return style
}

/**
 * Transform teacher data into specific object
 * @param {Object} teacher
 * @return {Object} transformed teacher
 */

export function transformTeacherData(teacher) {
  let data = {
    teacher_info: {
      first_name: teacher?.first_name || '',
      last_name: teacher?.last_name || '',
      username: teacher?.username || '',
      email: teacher?.email || '',
      phone: teacher?.phone || '',
      gender: teacher?.gender || '',
      address: teacher?.profile?.address || '',
      user_type: 'teacher',
    },
    profile_info: {
      blood_group: teacher?.profile?.blood_group || '',
      date_of_birth: teacher?.profile?.date_of_birth || '',
      religion: teacher?.profile?.religion || '',
      nationality: teacher?.profile?.nationality || '',
    },
    professional_info: {
      qualification: teacher?.professional_info?.qualification || '',
      join_date: teacher?.professional_info?.join_date || '',
      designation: teacher?.professional_info?.designation || '',
    },
    identity_info: {
      type: teacher?.identity_info?.type || '',
      value: teacher?.identity_info?.value || '',
      country: teacher?.identity_info?.country || '',
    },
    salary_info: {
      cycle: teacher?.salary_info?.cycle || '',
      allowance: teacher?.salary_info?.allowance || '',
      amount: teacher?.salary_info?.amount || '',
      other_perks: teacher?.salary_info?.other_perks || '',
    },
  }
  return data
}

export const unsetMode = (unsetList) => {
  unsetList.forEach((val) => {
    switch (val) {
      case 'student': {
        localStorage.removeItem('currentActiveStudent')
        break
      }
      case 'teacher': {
        localStorage.removeItem('currentActiveTeacher')
        break
      }
    }
  })
}

export function transformSuperAdminData(superAdminData) {
  let data = {
    super_admin_info: {
      first_name: superAdminData?.first_name,
      last_name: superAdminData?.last_name,
      email: superAdminData?.email,
      gender: superAdminData?.gender,
      user_type: 'super-admin',
    },
    profile_info: {
      blood_group: superAdminData?.profile?.blood_group,
      date_of_birth: superAdminData?.profile?.date_of_birth,
      religion: superAdminData?.profile?.religion,
      nationality: superAdminData?.profile?.nationality,
      address: superAdminData?.profile?.address,
    },
    identity_info: {
      type: superAdminData?.identity_info?.type,
      value: superAdminData?.identity_info?.value,
      country: superAdminData?.identity_info?.country,
    },
  }
  return data
}

export function transformStudentData(student) {
  let data = {
    student_info: {
      first_name: student?.first_name,
      last_name: student?.last_name,
      username: student?.username,
      email: student?.email,
      phone: student?.phone,
      gender: student?.gender,
      user_type: 'student',
      registration_no: student?.student_info?.registration_no,
    },
    profile_info: {
      blood_group: student?.profile?.blood_group,
      date_of_birth: student?.profile?.date_of_birth,
      religion: student?.profile?.religion,
      nationality: student?.profile?.nationality,
      address: student?.profile?.address,
    },
    guardian_info: {
      name: student?.guardian_info?.first_name,
      relation: student?.guardian_info?.relation,
      profession: student?.guardian_info?.profession,
      phone: student?.guardian_info?.phone,
      email: student?.guardian_info?.email,
      address: student?.guardian_info?.address,
    },
    identity_info: {
      type: student?.identity_info?.type,
      value: student?.identity_info?.value,
      country: student?.identity_info?.country,
    },
    roll_no: student?.users_roles_entities[0]?.roll_no,
  }
  return data
}

export function transformCampusAdminData(admin) {
  let data = {
    campus_admin_info: {
      first_name: admin?.first_name || '',
      last_name: admin?.last_name || '',
      email: admin?.email || '',
      phone: admin?.phone || '',
      gender: admin?.gender || '',
    },
    profile_info: {
      blood_group: admin?.profile?.blood_group || '',
      date_of_birth: admin?.profile?.date_of_birth || '',
      religion: admin?.profile?.religion || '',
      nationality: admin.profile?.nationality || '',
      height: admin?.profile?.height || '',
      weight: admin?.profile?.weight || '',
      address: admin?.profile?.address || '',
    },
    identity_info: {
      type: admin?.identity_info?.type || '',
      value: admin?.identity_info?.value || '',
      country: admin?.identity_info?.country || '',
    },
  }

  return data
}

export function transformGuardianData(guardian) {
  let data = {
    guardian_info: {
      first_name: guardian?.first_name || '',
      last_name: guardian?.last_name || '',
      email: guardian?.email || '',
      gender: guardian?.gender || '',
      user_type: 'guardian',
      phone: guardian?.phone || '',
      address: guardian?.profile?.address || '',
    },
    profile_info: {
      blood_group: guardian?.profile?.blood_group || '',
      date_of_birth: guardian?.profile?.date_of_birth || '',
      religion: guardian?.profile?.religion || '',
      nationality: guardian?.profile?.nationality || '',
      height: guardian?.profile?.height || '',
      weight: guardian?.profile?.weight || '',
      address: guardian?.profile?.address || '',
      image: guardian?.profile?.image || '',
    },
    identity_info: {
      type: guardian?.identity_info?.type || '',
      value: guardian?.identity_info?.value || '',
      country: guardian?.identity_info?.country || '',
    },
  }
  return data
}

export function switchCampusAndSection(currentRoute) {
  $store.commit('layout/SET_APP_LOADING', true)
  $store.dispatch('layout/getCampusList').then((res) => {
    let campusObj = ''
    let classObj = ''

    if (!res.data.length) {
      $store.commit('toast/NEW', {
        message: t('dashboard.NEIC'),
        type: 'error',
      })
      return
    } else {
      campusObj = res?.data[0]
      $store.commit('layout/GET_ALL_CAMPUS', res?.data)
      $store.commit('layout/SET_SELECTED_CAMPUSE', campusObj)

      $store
        .dispatch('layout/getUserSpecificSectionsList', { campuseId: campusObj.id })
        .then((res) => {
          if (res.data.length === 0) {
            $store.commit('toast/NEW', {
              message: t('dashboard.NEICLS'),
              type: 'error',
            })
            return
          } else {
            classObj = res?.data[0] || ''
            $store.commit('layout/SET_CLASS_LIST', res?.data)
            $store.commit('layout/SET_CURRENT_SELECTED_SECTION_SCOPE', classObj)
            redirectToAuthenticPage(currentRoute)
          }
        })
    }
  })
}

function redirectToAuthenticPage(currentRoute) {
  if (currentRoute === 'schedule-manager') $router.go()
  else $router.push({ name: 'schedule-manager' })
}
export function transformStaffData(staff) {
  let data = {
    // Staff Personal Info
    staff_info: {
      first_name: staff?.first_name,
      last_name: staff?.last_name,
      email: staff?.email || '',
      phone: staff?.phone || '',
      gender: staff?.gender || '',
      major_role: staff?.major_role || '',
      user_type: 'staff',
    },
    // Staff Profile Info
    profile_info: {
      address: staff?.profile?.address || '',
      blood_group: staff?.profile?.blood_group || '',
      date_of_birth: staff?.profile?.date_of_birth || '',
      religion: staff?.profile?.religion || '',
      nationality: staff?.profile?.nationality,
      passport: staff?.profile?.passport,
      cnic: staff?.profile?.cnic,
      driving_lisence: staff?.profile?.driving_lisence,
    },

    // Staff Professional Info
    professional_info: {
      qualification: staff?.professional_info?.qualification,
      join_date: staff?.professional_info?.join_date || '',
      designation: staff?.professional_info?.designation,
      salary: staff?.professional_info?.salary,
    },

    // Staff Identity Info
    identity_info: {
      type: staff?.identity_info?.type || '',
      value: staff?.identity_info?.value || '',
      country: staff?.identity_info?.country || '',
    },

    // Staff Salary Info
    salary_info: {
      cycle: staff?.salary_info?.cycle,
      allowance: staff?.salary_info?.allowance,
      amount: staff?.salary_info?.amount,
      other_perks: staff?.salary_info?.other_perks,
    },
  }

  return data
}

export function transformPickupMembersData(user) {
  let data = {
    pickup_person_info: {
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email || '',
      phone: user?.phone || '',
      gender: user?.gender || '',
      address: user?.profile?.address || '',
    },
    profile_info: {
      blood_group: user?.profile?.blood_group || '',
      date_of_birth: user?.profile?.date_of_birth || '',
      religion: user?.profile?.religion || '',
      religion: user?.profile?.religion || '',
    },

    identity_info: {
      type: user?.identity_info?.type || '',
      value: user?.identity_info?.value || '',
      country: user?.identity_info?.country || '',
    },
  }

  return data
}

export function userLoginStatus(isLogin) {
  let status = isLogin ? GENERAL_CONSTANT.LOGIN_ENABLED : GENERAL_CONSTANT.LOGIN_DISABLED
  return status
}

export function fullName(user = { first_name: '', last_name: '' }) {
  user['full_name'] = `${user.first_name} ${user.last_name}`
}

export function redirectToStudentMode(
  student,
  path = 'students',
  redirectTo = 'student-profile-mode',
) {
  const notAllowedUsers = [
    TENANT_ROLES.GUARDIAN,
    TENANT_ROLES.SECTION_STUDENT,
    TENANT_ROLES.PICKUP_PERSON,
  ]
  const activeRole = $store.state.layout.activeRole

  if (notAllowedUsers.includes(activeRole)) return
  localStorage.setItem('currentActiveStudent', JSON.stringify(student))
  localStorage.setItem('redirectTo', path)
  $router.push({ name: redirectTo, params: { student_id: student.id } })
}

export function redirectToTeacherMode(teacher, path = 'teachers', redirectTo = 'teacher-profile') {
  localStorage.setItem('currentActiveTeacher', JSON.stringify(teacher))
  localStorage.setItem('redirectTo', path)
  $router.push({ name: redirectTo })
}

export function getOptionsBasedOnUserStatus(isLoginEnable, isManualPasswordResetAllowed) {
  let options = []
  if (isLoginEnable && isManualPasswordResetAllowed)
    options.unshift({ name: 'TV_SUSPEND_ACCOUNT' }, { name: 'TV_RP' }, { name: 'TV_RE' })
  else if (isLoginEnable) options.unshift({ name: 'TV_SUSPEND_ACCOUNT' }, { name: 'TV_RE' })
  else options.unshift({ name: 'TV_ACTIVATE_ACCOUNT' })
  return options
}
